<template>
    <div>
        <vue-header :fixed="true" title="壽養集" />
        <div class="red-packet">
            <img src="../../../../images/redPacket.png"/>
            <router-link :to="`/confirmAssess/7?sys_company_id=${sys_company_id}`" class="red-position" v-if="sys_company_id">
                领取前往检测
                <div class="packet">价值¥99的健康红包</div>
            </router-link>
        </div>
        <div class="packet-bottom flex">
            <div class="packet-share flex-1" @click="shareFun">分享给好友使用</div>
            <router-link :to="`/confirmAssess/7?sys_company_id=${sys_company_id}`" class="packet-draw" v-if="sys_company_id">立即领取</router-link>
        </div>
        <div class="alert" v-if="shareBool" @click="closeFun">
            <div class="alert-top">
                <img src="../../../../images/share.png" class="alert-share" />
            </div>
        </div>
    </div>
</template>
<script>
import vueHeader from '@/components/header';
import { mapActions } from 'vuex';
export default {
  name: 'redPacket',
  components:{
    vueHeader
  },
  data(){ 
        return {
            shareBool:false,
            sys_company_id:0
        }
    },
  created:function(){ 
    this.configData();
    this.sys_company_id = this.$route.params.id;
  },
  methods:{
      closeFun(){
          this.shareBool=false;
      },
      shareFun(){
          let wx  = window.wx;
          let _this = this;
            wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
                _this.shareBool = true;
                wx.updateAppMessageShareData({ 
                    title: `壽養集送您健康大红包啦`, // 分享标题
                    desc: '送您一张价值99元的AI中医健康评估大红包，随时掌握健康状态！', // 分享描述
                    link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: `${document.location.protocol}//${window.location.host}/share.png`, // 分享图标
                    success: function () {} 
                })
                wx.updateTimelineShareData({ 
                    title: `壽養集送您健康大红包啦`, // 分享标题
                    link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: `${document.location.protocol}//${window.location.host}/share.png`, // 分享图标
                    success: function () {}
                })
            });
      },
      clickFun(){
          
      },
      ...mapActions('user',['configData'])
  }
}
</script>

<style lang="scss" scoped rel="stylesheet/scss">
  @import 'redPacket';
</style>